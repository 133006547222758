/**
 * To use, use a returnToken2 or latestMargo then construct a url
 * like: http://localhost:8081/testing/auto/latestMargo
 */

import { RouteConfig } from 'vue-router'
import { getApplicantReturningForTesting } from '@/services/api'

const routeName: string = 'testing'

const pageNames = {
    AUTO: 'auto',
}

export const testingRoutePaths = {
    AUTO: `/${routeName}/${pageNames.AUTO}/:returnToken`,
}

export const testingRoutes: RouteConfig[] = [
    {
        path: testingRoutePaths.AUTO,
        name: pageNames.AUTO,
        component: () => import(/* webpackChunkName: "origination-jodl-testing" */ '@/pages/shared/UnapprovedPage.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
        beforeEnter: async (to, from, next) => {
            const { returnToken } = to.params
            await getApplicantReturningForTesting(returnToken)
            next()
        },
    },
]
