export enum RouteOption {
    identityQuestions = 'identityQuestions',
    irsFormVerify = 'irsFormVerify',
    payStubsVerify = 'payStubsVerify',
    taxReturnVerify = 'taxReturnVerify',
    otherIncomeVerify = 'otherIncomeVerify',
    fortifiedIncomeVerify = 'fortifiedIncomeVerify',
    awaitingManualDocumentVerification = 'awaitingManualDocumentVerification',
    hmda = 'hmda',
    remedyNameMismatch = 'remedyNameMismatch',
    remedyNameMismatchTokenMatch = 'remedyNameMismatchTokenMatch',
    experianFrozen = 'experianFrozen',
    supportingInfo = 'supportingInfo',
    ssnVerification = 'ssnVerification',
    addCoApplicantOnFailure = 'addCoApplicantOnFailure',
    disputableProviderData = 'disputableProviderData',
    irsForAllPQ = 'irsForAllPQ',
    irsForAllIV = 'irsForAllIV',
    rentalIncomeVerification = 'rentalIncomeVerification',
    secondLienAttestation = 'secondLienAttestation',
    mortgageInfoAttestation = 'mortgageInfoAttestation',

    coApplicantSsnVerification = 'coApplicantSsnVerification',
    coApplicantExperianFrozen = 'coApplicantExperianFrozen',

    selfEmploymentBusinessPercentage = 'selfEmploymentBusinessPercentage',

    // for churn retention flow
    balanceSweepPQ = 'balanceSweepPQ',
    retentionOfferAcceptedNoCliBenefit = 'retentionOfferAcceptedNoCliBenefit',

    // for ucc
    assetVerification = 'assetVerification',
}

interface FlowInfo {
    parent: string | null
    flow: string[] | null
}

export const getNormalizedPath = (path: string): string => {
    return path.split('/').filter(Boolean).join('/').toLowerCase()
}

export const checkPathsMatch = (pathOne: string, pathTwo: string): boolean => {
    const normalizedPathOne = getNormalizedPath(pathOne)
    const normalizedPathTwo = getNormalizedPath(pathTwo)

    const segmentsOne = normalizedPathOne.split('/')
    const segmentsTwo = normalizedPathTwo.split('/')

    // Paths are not the same if they have different numbers of segments
    if (segmentsOne.length !== segmentsTwo.length) {
        return false
    }

    for (let i = 0; i < segmentsOne.length; i++) {
        const isSegmentOneParam = segmentsOne[i].startsWith(':')
        const isSegmentTwoParam = segmentsTwo[i].startsWith(':')
        if (segmentsOne[i] !== segmentsTwo[i] && !isSegmentOneParam && !isSegmentTwoParam) {
            return false
        }
    }

    // All segments match
    return true
}

export const tryGetNextFlowPath = (currPath: string, mainFlow: string[], optionalFlowMap: Map<string, Map<string, string[]>>): string | null => {
    const currFlowInfo: FlowInfo = getCurrentFlowInfo(currPath, mainFlow, optionalFlowMap)
    if (!currFlowInfo.flow) {
        return null
    }

    const nextIdx = currFlowInfo.flow.findIndex((path) => checkPathsMatch(path, currPath)) + 1
    if (nextIdx < currFlowInfo.flow.length) {
        return currFlowInfo.flow[nextIdx]
    }
    return currFlowInfo.parent
}

const getCurrentFlowInfo = (currPath: string, mainFlow: string[], optionalFlowMap: Map<string, Map<string, string[]>>): FlowInfo => {
    if (mainFlow.findIndex((path) => checkPathsMatch(path, currPath)) >= 0) {
        return { parent: null, flow: mainFlow }
    }
    for (const [parent, optionalFlowGroup] of optionalFlowMap.entries()) {
        for (const optionalFlow of optionalFlowGroup.values()) {
            if (optionalFlow.findIndex((path) => checkPathsMatch(path, currPath)) >= 0) {
                return { parent, flow: optionalFlow }
            }
        }
    }
    return { parent: null, flow: null }
}

export const tryGetOptionalPath = (currPath: string, optionalFlowMap: Map<string, Map<string, string[]>>, routeOption: string): string | null => {
    const optionalFlowGroup = optionalFlowMap.get(currPath)
    const optionalFlow = optionalFlowGroup?.get(routeOption)

    if (optionalFlow && optionalFlow.length > 0) {
        return optionalFlow[0]
    }
    return null
}

export const checkAreAllPathsUnique = (mainFlow: string[], optionalFlowMap: Map<string, Map<string, string[]>>): boolean => {
    const pathMap: Set<string> = new Set()
    for (const path of mainFlow) {
        if (getNormalizedPath(path) in pathMap) {
            return false
        }
        pathMap.add(getNormalizedPath(path))
    }
    for (const optionalFlowGroup of optionalFlowMap.values()) {
        for (const optionalFlow of optionalFlowGroup.values()) {
            for (const path of optionalFlow) {
                if (getNormalizedPath(path) in pathMap) {
                    return false
                }
                pathMap.add(getNormalizedPath(path))
            }
        }
    }
    return true
}
