import { RouteConfig } from 'vue-router'

export const pifForAllPageNames = {
    /**
     * The landing page
     */
    PIF_FOR_ALL_LANDING: 'pifForAll',
    /**
     * PIF sender enters their personal info
     */
    SENDER_INFO: 'senderInfo',
    /**
     * Displays the PIF sender's link
     */
    LINK: 'link',
}

// reward type is meant to support different variations of the Pay It Forward concept
export enum PifRewardType {
    classicPif = 'classicPif', // classic PIF
    travelRewardsV0 = 'travelRewardsV0', // Vegas Tickets
    travelTicketsBahamas = 'travelTicketsBahamas', // Bahamas Tickets
}

export const pifForAllPagePaths = {
    PIF_FOR_ALL_LANDING: `/${pifForAllPageNames.PIF_FOR_ALL_LANDING}`,
    SENDER_INFO: `/pifForAll/${pifForAllPageNames.SENDER_INFO}`,
    LINK: `/pifForAll/${pifForAllPageNames.LINK}`,
}

export const pifForAllPageRoutes: RouteConfig[] = [
    {
        path: pifForAllPagePaths.PIF_FOR_ALL_LANDING,
        name: pifForAllPageNames.PIF_FOR_ALL_LANDING,
        component: () => import(/* webpackChunkName: "pif-for-all-landing" */ '@/experiments/src/pages/marketing/PifForAllLandingPage.vue'),
        meta: { public: true },
    },
    {
        name: pifForAllPageNames.SENDER_INFO,
        path: pifForAllPagePaths.SENDER_INFO,
        component: () => import(/* webpackChunkName: "pif-for-sender-info" */ '@/pages/pifForAll/PifForAllSenderInfo.vue'),
        meta: { public: false },
    },
    {
        name: pifForAllPageNames.LINK,
        path: pifForAllPagePaths.LINK,
        component: () => import(/* webpackChunkName: "pif-for-all-link" */ '@/pages/pifForAll/PifForAllLink.vue'),
        meta: { public: false },
        props: true,
    },
]
