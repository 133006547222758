// @ts-strict
import { logger } from '@/utils/logger'
import { appSessionStorage, sessionStorageKey } from './storage'
import router from '@/routes/router'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { i18n } from '@/utils/i18n'
import { isIOSSafari } from '@/utils/parseUserAgents'
import { ThanksPageReasons } from '@/utils/thanksPageHelpers'

// See axios/lib/core/enhanceError.js for fields
interface AxiosError extends Error {
    request?: any
    response?: any
    code?: string
    isAxiosError?: boolean
}

const enhancedMessage = (message: string, enhancement?: string): string => {
    return `${message}${enhancement ? ` (${enhancement})` : ''}`
}

const ApiErrorHandler = function (error: AxiosError, message?: string) {
    if (error?.response?.status === undefined || error?.response?.status === 0) {
        logger.error(enhancedMessage(`Network error`, message), error)
        return i18n.tc('global.errors.generic')
    }

    // Can add other errors
    switch (error.response.status) {
        case 401:
            if (!appSessionStorage.getItem(sessionStorageKey.jwtTokens)) {
                if (isIOSSafari()) {
                    logger.error(enhancedMessage(`Got 401 error, and in private browsing. Redirecting...`, message), error)
                    router.push({ path: sharedPagePaths.THANKS, query: { reason: ThanksPageReasons.privateBrowsing } })
                    break
                }
            }
            appSessionStorage.removeItem(sessionStorageKey.jwtTokens)
            logger.error(enhancedMessage(`Got 401 error, not in private browsing. Redirecting...`, message), error)
            router.push({ path: sharedPagePaths.THANKS, query: { reason: ThanksPageReasons.authorization } })
            break
        case 403:
            appSessionStorage.removeItem(sessionStorageKey.jwtTokens)
            logger.error(enhancedMessage(`Got 403 error (application is closed). Redirecting...`, message), error)
            router.push({ path: sharedPagePaths.THANKS, query: { reason: ThanksPageReasons.closed } })
            break
        case 400:
            // Todo Bad Request often occurs when an applicant types in an invalid email
            //  address through PersonalInfo.vue, e.g. justin@yahoo.con. Ideally, we don't
            //  want to page the on-call engineer for an error like this. However, we don't
            //  want to miss legitimate Bad Request errors, so for now we'll keep this at
            //  the error level. Eventually we need to implement data validation that passes
            //  helpful errors back to the user when they make a mistake.
            //  https://app.asana.com/0/1127284905527130/1200616509179984/f
            logger.fatal(enhancedMessage(`Bad request`, message), error)
            return i18n.tc('global.errors.badRequest')
        case 404:
            logger.error(enhancedMessage(`Got 404 Not Found error`, message), error)
            router.push({
                path: sharedPagePaths.THANKS,
                query: {
                    reason: ThanksPageReasons.resourceNotFound,
                },
            })
            break
        default:
            logger.fatal(enhancedMessage(`API error`, message), error)
            // Temporarily redirect 500 errors to Murtada
            router.push({
                path: sharedPagePaths.THANKS,
                query: {
                    reason: ThanksPageReasons.review,
                },
            })
    }

    return ''
}

export { ApiErrorHandler }
