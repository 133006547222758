/**
 * ------------------------------------------- Configuration of Flows -------------------------------------------
 *
 *       q - r - s  <- optional flow
 *       |
 *   a - b - c - d - e - f  <- main flow
 *       | /         |
 *       g - h       i - j  <- optional flow
 *                       |
 *                       k - l - m - n - o  <- optional flow
 *
 * mainFlow = [a, b, c, d, e, f]
 * Optional flows: qFlow = [q, r, s]; gFlow = [g, h]; iFlow = [i, j]; kFlow = [k, l, m, n, o]
 * Each flow is described by an array of page paths (strings) starting at index 0 and ending at the last index.
 * !!!Note that all page paths must be unique!!!!, otherwise a secondary identifier must be used for discrimination.
 *
 * optionalFlowMap = {b: {qOption: qFlow, gOption: gFlow}, c: {gOption: gFlow}, e: {iOption: iFlow}, j: {kOption: kFlow}}
 * The optional flow map describes the children of each page in terms of secondary (optional) flows.
 *
 * qOption = RouteOption.qFlowIdentifier, gOption = RouteOption.gFlowIdentifier, etc...
 * Each page (parent) may have several optional routes (children). A particular child of a page is specified
 * by referencing a RouteOption parameter (enum). See f.e. the optional routes accessible from page b.
 *
 * Once the end of an optional flow is reached, !!!the next page will be the parent of that optional flow!!!
 */

import { originationPagePaths } from '@/routes/originationRoutes'
import { marketingPagePaths } from '@/routes/marketingRoutes'
import { checkAreAllPathsUnique, checkPathsMatch, getNormalizedPath, RouteOption, tryGetNextFlowPath, tryGetOptionalPath } from '@/flow/flowUtility'
import { authPagePaths } from '@/routes/authRoutes'
import { experimentPagePaths } from '@/experiments/src/routes/marketingRoutes'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { docRoutePaths } from '@/routes/docRoutes'

export const originationEntryPagePaths: string[] = [
    marketingPagePaths.LANDING,
    marketingPagePaths.LANDING_JOIN,
    marketingPagePaths.LANDING_INVITE,
    marketingPagePaths.PIF_LANDING,
    marketingPagePaths.PIF_TRAVEL_LANDING,
    marketingPagePaths.PIF_TRAVEL_BAHAMAS,
    marketingPagePaths.LINK_PREVIEW_HELOC_CREDIT_CARD,
    marketingPagePaths.LINK_PREVIEW_COMPARE_HELOC,
    marketingPagePaths.LINK_PREVIEW_CLOSE_FAST,
    marketingPagePaths.LINK_PREVIEW_NOTARY_ONLINE,
    ...Object.values(experimentPagePaths),
]

export const originationReturnPagePathPairs: Record<string, string> = {
    [getNormalizedPath(authPagePaths.OFFER_RETURN)]: originationPagePaths.OFFER_PREVIEW,
    [getNormalizedPath(docRoutePaths.DOCS_UPLOAD_INCOME_VERIFICATION)]: originationPagePaths.OFFER_PREVIEW,
    [getNormalizedPath(originationPagePaths.INCOME_VERIFICATION_IN_PROGRESS)]: originationPagePaths.OFFER_PREVIEW,
    [getNormalizedPath(originationPagePaths.INCOME_DOCUMENT_UPLOAD_PORTAL)]: originationPagePaths.OFFER_PREVIEW,
    [getNormalizedPath(authPagePaths.INCOME_VERIFICATION_RETURN)]: originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION,
}

export const originationBackGuardPagePaths: string[] = [
    originationPagePaths.OFFER_PREVIEW,
    originationPagePaths.IDENTITY_QUESTIONS,
    originationPagePaths.CO_APPLICANT_IDENTITY_QUESTIONS,
    originationPagePaths.AWAITING_MANUAL_DOCUMENT_VERIFICATION,
    originationPagePaths.OFFER_ADD_CO_APPLICANT_ON_FAILURE,
    originationPagePaths.ADD_CO_APPLICANT_INFO, // specifically add coApplicant info following offer add coApplicant page
    originationPagePaths.STATED_INCOME,
    sharedPagePaths.THANKS,
    sharedPagePaths.LOAN_NOT_APPROVED,
].map(getNormalizedPath)

export const originationFlow: string[] = [
    originationPagePaths.STATED_URGENCY,
    originationPagePaths.STATED_USAGE,
    originationPagePaths.EMAIL_SSN,
    originationPagePaths.PERSONAL_INFO,
    originationPagePaths.STATED_REFERRER,
    originationPagePaths.VERIFY_IDENTITY,
    originationPagePaths.CO_APPLICANT_VERIFY_IDENTITY,
    originationPagePaths.STATED_INCOME,
    originationPagePaths.STATED_TOTAL_SAVINGS,
    originationPagePaths.PRE_QUALIFICATION,
    // Todo This is currently an experimental page when the applicant chooses a PrimeV3 offer.
    //  If the chosen offer is not PrimeV3, this page's internal logic just skips ahead to the
    //  next page in the flow.
    originationPagePaths.LINE_DRAW_APR_REDUCTION,
    originationPagePaths.FIRST_LIEN_ATTESTATION,
    originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION,
    originationPagePaths.INCOME_VERIFICATION,
    originationPagePaths.OFFER_PREVIEW,
    originationPagePaths.FLOOD_INSURANCE_VERIFICATION,
    originationPagePaths.SECONDARY_SIGNERS,
    originationPagePaths.SECONDARY_SIGNERS_UPDATE,
    originationPagePaths.TRUST_INFO_SUBMISSION,
    originationPagePaths.FORWARD_TO_NOTARIZATION,
]

const identityQuestionsFlow: string[] = [originationPagePaths.IDENTITY_QUESTIONS]
const ssnVerificationFlow: string[] = [originationPagePaths.SSN_VERIFICATION]
const identityFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.identityQuestions, identityQuestionsFlow],
    [RouteOption.ssnVerification, ssnVerificationFlow],
])

const coApplicantSsnVerificationFlow: string[] = [originationPagePaths.CO_APPLICANT_SSN_VERIFICATION]
const coApplicantIdentityQuestionsFlow: string[] = [originationPagePaths.CO_APPLICANT_IDENTITY_QUESTIONS]
const coApplicantIdentityFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.identityQuestions, coApplicantIdentityQuestionsFlow],
    [RouteOption.coApplicantSsnVerification, coApplicantSsnVerificationFlow],
])

const experianFrozenFlow: string[] = [originationPagePaths.EXPERIAN_FROZEN]
const coApplicantExperianFrozenFlow: string[] = [originationPagePaths.CO_APPLICANT_EXPERIAN_FROZEN]
const addCoApplicantOnFailureFlow: string[] = [
    originationPagePaths.OFFER_ADD_CO_APPLICANT_ON_FAILURE,
    originationPagePaths.ADD_CO_APPLICANT_INFO,
    // Manual redirection to co applicant verify identity from previous page...
]

const irsForAllPQFlow = [originationPagePaths.IRS_FOR_ALL_PQ]
const irsForAllIVFlow = [originationPagePaths.IRS_FOR_ALL_IV]
const rentalIncomeVerificationFlow = [
    originationPagePaths.RENTAL_INCOME_VERIFICATION,
    originationPagePaths.CURRENT_RENT_VERIFICATION_SELECTION,
    originationPagePaths.CURRENT_RENT_VERIFICATION_DOCUMENT_UPLOAD,
]
const employmentIncomeVerificationGroup: Map<string, string[]> = new Map([
    [RouteOption.irsForAllIV, irsForAllIVFlow],
    [RouteOption.rentalIncomeVerification, rentalIncomeVerificationFlow],
])
const secondLienAttestationFlow = [originationPagePaths.SECOND_LIEN_ATTESTATION]
const mortgageInfoAttestationFlow = [originationPagePaths.MORTGAGE_INFO_ATTESTATION]

const remedyNameMismatchFlow = [originationPagePaths.REMEDY_NAME_MISMATCH]
const remedyNameMismatchTokenMatchFlow = [originationPagePaths.REMEDY_NAME_MISMATCH_TOKEN_MATCH]

const hmdaFlow = [originationPagePaths.HMDA]

const disputableProviderDataFlow: string[] = [originationPagePaths.DISPUTE_PROVIDER_DATA]
const preQualificationFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.experianFrozen, experianFrozenFlow],
    [RouteOption.coApplicantExperianFrozen, coApplicantExperianFrozenFlow],
    [RouteOption.addCoApplicantOnFailure, addCoApplicantOnFailureFlow],
    [RouteOption.remedyNameMismatch, remedyNameMismatchFlow],
    [RouteOption.remedyNameMismatchTokenMatch, remedyNameMismatchTokenMatchFlow],
    [RouteOption.coApplicantSsnVerification, coApplicantSsnVerificationFlow],
    [RouteOption.disputableProviderData, disputableProviderDataFlow],
    [RouteOption.irsForAllPQ, irsForAllPQFlow],
    [RouteOption.secondLienAttestation, secondLienAttestationFlow],
    [RouteOption.mortgageInfoAttestation, mortgageInfoAttestationFlow],
])

const irsFormFlow = [originationPagePaths.IRS_FORM_VERIFICATION]
const payStubsFlow = [originationPagePaths.PAY_STUB_VERIFICATION]
const taxReturnFlow = [originationPagePaths.TAX_RETURN_VERIFICATION]
const otherIncomeVerificationFlow = [originationPagePaths.OTHER_INCOME_VERIFICATION]
const fortifiedIncomeVerificationFlow = [originationPagePaths.FORTIFIED_INCOME_VERIFICATION]
const selfEmploymentBusinessPercentageFlow = [originationPagePaths.SELF_EMPLOYMENT_BUSINESS_PERCENTAGE]
const incomeVerificationFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.irsFormVerify, irsFormFlow],
    [RouteOption.taxReturnVerify, taxReturnFlow],
    [RouteOption.payStubsVerify, payStubsFlow],
    [RouteOption.otherIncomeVerify, otherIncomeVerificationFlow],
    [RouteOption.fortifiedIncomeVerify, fortifiedIncomeVerificationFlow],
    [RouteOption.selfEmploymentBusinessPercentage, selfEmploymentBusinessPercentageFlow],
    [RouteOption.rentalIncomeVerification, rentalIncomeVerificationFlow],
])

const supportingInfoFlow = [originationPagePaths.PROVIDE_SUPPORTING_INFORMATION]
const awaitingManualDocumentVerificationFlow = [originationPagePaths.AWAITING_MANUAL_DOCUMENT_VERIFICATION]
const offerPreviewFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.supportingInfo, supportingInfoFlow],
    [RouteOption.awaitingManualDocumentVerification, awaitingManualDocumentVerificationFlow],
    [RouteOption.remedyNameMismatch, remedyNameMismatchFlow],
    [RouteOption.hmda, hmdaFlow],
])

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map([
    [originationPagePaths.VERIFY_IDENTITY, identityFlowGroup],
    [originationPagePaths.CO_APPLICANT_VERIFY_IDENTITY, coApplicantIdentityFlowGroup],
    [originationPagePaths.PRE_QUALIFICATION, preQualificationFlowGroup],
    [originationPagePaths.INCOME_VERIFICATION, incomeVerificationFlowGroup],
    [originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION, employmentIncomeVerificationGroup],
    [originationPagePaths.OFFER_PREVIEW, offerPreviewFlowGroup],
])

// Could be a unit test
if (!checkAreAllPathsUnique(originationFlow, optionalFlowMap)) {
    throw new Error('origination page paths not unique!')
}

export const getOriginationNextRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    if (routeOption) {
        return tryGetOptionalPath(currentPath, optionalFlowMap, routeOption)
    }
    if (originationEntryPagePaths.some((path) => checkPathsMatch(path, currentPath))) {
        return originationFlow[0]
    }

    const matchingReturnPagePath: string | undefined = originationReturnPagePathPairs[getNormalizedPath(currentPath)]
    if (matchingReturnPagePath) {
        return matchingReturnPagePath
    }

    return tryGetNextFlowPath(currentPath, originationFlow, optionalFlowMap)
}
