"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeUnderwritingMode = exports.UCC_MAX_APR = exports.AUTO_CREDIT_CARD_ACCOUNT_MAX_APR = exports.HOME_CREDIT_CARD_ACCOUNT_MAX_APR = exports.OfferFailureCode = void 0;
/**
 * Reasons why Aven card offer generation may have failed. Notice this is a superset of
 * UnderwritingFailureCode, meant to account for both underwriting failures and non-underwriting
 * failures. This is our external error interface communicated to our client code.
 */
var OfferFailureCode;
(function (OfferFailureCode) {
    OfferFailureCode["homeOwnership"] = "homeOwnership";
    OfferFailureCode["homeOwnershipRemedy"] = "homeOwnershipRemedy";
    OfferFailureCode["homeOwnershipRemedyTokenMatch"] = "homeOwnershipRemedyTokenMatch";
    OfferFailureCode["homeIsLLC"] = "homeIsLLC";
    OfferFailureCode["homeIsTrustAndRin"] = "homeIsTrustAndRin";
    OfferFailureCode["homeIsTrustAndNOO"] = "homeIsTrustAndNOO";
    OfferFailureCode["homeIsTrustInNotSupportedState"] = "homeIsTrustInNotSupportedState";
    OfferFailureCode["humanInvestigate"] = "humanInvestigate";
    OfferFailureCode["involuntaryLienFound"] = "involuntaryLienFound";
    OfferFailureCode["activeForeclosureFound"] = "activeForeclosureFound";
    OfferFailureCode["ofacVerification"] = "ofacVerification";
    OfferFailureCode["denied"] = "denied";
    OfferFailureCode["expired"] = "expired";
    OfferFailureCode["priorApplicationFound"] = "priorApplicationFound";
    OfferFailureCode["offerAddCoApplicant"] = "offerAddCoApplicant";
    OfferFailureCode["blockedApplication"] = "blockedApplication";
    OfferFailureCode["underwritingFail"] = "underwritingFail";
    OfferFailureCode["disputableProviderData"] = "disputableProviderData";
    OfferFailureCode["lienInformationVerification"] = "lienInformationVerification";
    OfferFailureCode["missingLienAttestation"] = "lienAttestationMissing";
    OfferFailureCode["withdrawn"] = "withdrawn";
})(OfferFailureCode = exports.OfferFailureCode || (exports.OfferFailureCode = {}));
/** @TranslateToPython */
exports.HOME_CREDIT_CARD_ACCOUNT_MAX_APR = 0.18;
/**
 * @TranslateToPython
 * not currently used
 */
exports.AUTO_CREDIT_CARD_ACCOUNT_MAX_APR = 0.2;
/** @TranslateToPython */
exports.UCC_MAX_APR = 0.2;
/**  @TranslateToPython  */
var HomeUnderwritingMode;
(function (HomeUnderwritingMode) {
    // TODO: split out origination for preQualificatoin and qualification UW
    HomeUnderwritingMode["origination"] = "origination";
    HomeUnderwritingMode["experianOnly"] = "experianOnly";
    HomeUnderwritingMode["annualRenewal"] = "annualRenewal";
    HomeUnderwritingMode["preAccountApprovalChecks"] = "preAccountApprovalChecks";
    HomeUnderwritingMode["postAccountOpeningLienCheck"] = "postAccountOpeningLienCheck";
    HomeUnderwritingMode["lineSizeIncrease"] = "lineSizeIncrease";
    HomeUnderwritingMode["lineSizeIncreaseExperianOnly"] = "lineSizeIncreaseExperianOnly";
    HomeUnderwritingMode["aprReduction"] = "aprReduction";
    HomeUnderwritingMode["mortgageBrokering"] = "mortgageBrokering";
    HomeUnderwritingMode["lienInfoCalculation"] = "lienInfoCalculation";
    HomeUnderwritingMode["fullReUnderwriting"] = "fullReUnderwriting";
    HomeUnderwritingMode["getLienInfo"] = "getLienInfo";
    HomeUnderwritingMode["parsePlaidReport"] = "parsePlaidReport";
    HomeUnderwritingMode["ucc"] = "ucc";
})(HomeUnderwritingMode = exports.HomeUnderwritingMode || (exports.HomeUnderwritingMode = {}));
