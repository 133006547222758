import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { markStateStale } from '@/utils/stateUtils'
import { experimentPagePaths } from '@/experiments/src/routes/marketingRoutes'

export const goToPageWithPartialState = (preservedKeys: string[], href: string, preserveSearch?: boolean) => {
    if (preservedKeys.length > 0) {
        logger.info(`Going to ${href} with partial state. Clearing appSessionStorage...`)
        appSessionStorage.clearWithException(preservedKeys)
    } else {
        logger.info(`Going to ${href} with clean state. Clearing appSessionStorage...`)
        appSessionStorage.clear()
    }

    const [path] = (href || '').split('?')
    if (preserveSearch) {
        // Pass href as we were given
        window.location.href = href
    } else {
        // pass just the path, without ?foo=bar...
        window.location.href = path
    }
}

export const goToPageWithCleanState = (href: string, preserveSearch?: boolean) => {
    goToPageWithPartialState([], href, preserveSearch)
}

export const goToInitialPageWithPartialState = (preservedKeys: string[], preserveSearch?: boolean) => {
    const startPagePath = appSessionStorage.getItem(sessionStorageKey.startPagePath) || '/'
    return goToPageWithPartialState(preservedKeys, startPagePath, preserveSearch)
}

export const goToInitialPageWithCleanState = (preserveSearch?: boolean) => {
    const startPagePath = appSessionStorage.getItem(sessionStorageKey.startPagePath) || '/'
    return goToPageWithCleanState(startPagePath, preserveSearch)
}

export const reloadPageWithCleanState = () => {
    logger.info(`Reloading to ${window.location.href} with clean state. Marking current state as stale...`)
    // Mark state as stale so init in main.ts will delete state and continue with fresh session
    markStateStale()
    return window.location.reload()
}

export const getStartPagePath = (): string => {
    const startPath = appSessionStorage.getItem(sessionStorageKey.startPagePath) || ''
    if (Object.values(experimentPagePaths).includes(startPath as any)) {
        return startPath
    }
    return '/'
}
