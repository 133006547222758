"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOCUSIGN_ERROR_EVENTS = exports.DOCUSIGN_SUCCESS_EVENTS = exports.DocusignEvent = void 0;
var DocusignEvent;
(function (DocusignEvent) {
    DocusignEvent["signing_complete"] = "signing_complete";
    DocusignEvent["decline"] = "decline";
    DocusignEvent["viewing_complete"] = "viewing_complete";
    DocusignEvent["cancel"] = "cancel";
    DocusignEvent["exception"] = "exception";
    DocusignEvent["session_timeout"] = "session_timeout";
    DocusignEvent["ttl_expired"] = "ttl_expired";
    DocusignEvent["id_check_failed"] = "id_check_failed";
})(DocusignEvent = exports.DocusignEvent || (exports.DocusignEvent = {}));
exports.DOCUSIGN_SUCCESS_EVENTS = [DocusignEvent.signing_complete, DocusignEvent.decline, DocusignEvent.viewing_complete, DocusignEvent.cancel];
exports.DOCUSIGN_ERROR_EVENTS = [DocusignEvent.exception, DocusignEvent.session_timeout, DocusignEvent.ttl_expired, DocusignEvent.id_check_failed];
