"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NextHomeApplicationAction = void 0;
// Keep in sync with NextHomeApplicationAction in aven project (frontend)
var NextHomeApplicationAction;
(function (NextHomeApplicationAction) {
    // Application should be redirected to Stated Usage page
    NextHomeApplicationAction["STATED_USAGE"] = "statedUsage";
    // Applicant should be redirected to Personal Info page
    NextHomeApplicationAction["PERSONAL_INFO"] = "personalInfo";
    // Applicant should be redirected to Stated Income page
    NextHomeApplicationAction["STATED_INCOME"] = "statedIncome";
    // Applicant should be redirected to Identity Verification page
    NextHomeApplicationAction["VERIFY_IDENTITY"] = "verifyIdentity";
    // Applicant should be directed to the Thanks page
    NextHomeApplicationAction["THANKS"] = "thanks";
    // Applicant should be directed to offer add joint co applicant page
    NextHomeApplicationAction["OFFER_ADD_COAPPLICANT"] = "offerAddCoApplicant";
    NextHomeApplicationAction["MORTGAGE_STATEMENT"] = "mortgageStatement";
    // Used in UCC for renters
    NextHomeApplicationAction["ASSET_VERIFICATION"] = "assetVerification";
    // Applicant should view the pre-qual page
    NextHomeApplicationAction["PRE_QUALIFICATION"] = "preQualification";
    // Applicant should view the hmda questionnaire
    NextHomeApplicationAction["HMDA"] = "hmda";
    // Applicant should view the appropriate income verification page
    NextHomeApplicationAction["EMPLOYMENT_INCOME_VERIFICATION"] = "employmentIncomeVerification";
    NextHomeApplicationAction["INCOME_VERIFICATION_TAX_RETURN"] = "incomeVerificationTaxReturn";
    NextHomeApplicationAction["INCOME_VERIFICATION_BANK_STATEMENT"] = "incomeVerificationBankStatement";
    NextHomeApplicationAction["INCOME_VERIFICATION_PAY_STUB"] = "incomeVerificationPayStub";
    NextHomeApplicationAction["INCOME_VERIFICATION_DOCS_UPLOADED_AND_PROCESSED"] = "incomeVerificationDocsUploadedAndProcessed";
    NextHomeApplicationAction["INCOME_VERIFICATION_DOCS_UPLOADED_BUT_UNDER_PROCEESSING"] = "incomeVerificationDocsUploadedButUnderProcessing";
    NextHomeApplicationAction["INCOME_DOCUMENT_UPLOAD_PORTAL"] = "incomeDocumentUploadPortal";
    NextHomeApplicationAction["CURRENT_RENT_VERIFICATION_SELECTION"] = "currentRentVerificationSelection";
    // Applicant should view the offer preview page
    NextHomeApplicationAction["OFFER_PREVIEW"] = "offerPreview";
    // Applicant should view the appropriate flood insurance verification page
    NextHomeApplicationAction["FLOOD_INSURANCE_VERIFICATION"] = "floodInsuranceVerification";
    // Applicant should be redirected to either coOwner page OR trust page
    // i.e. whatever they would normally see after accepting an offer
    NextHomeApplicationAction["POST_ACCEPT"] = "postAccept";
    // Applicant should be directed to the notary product
    NextHomeApplicationAction["NOTARIZATION"] = "notarization";
    // Applicant is already a customer so they should view the Welcome page
    NextHomeApplicationAction["WELCOME"] = "welcome";
    NextHomeApplicationAction["DENIED"] = "denied";
    NextHomeApplicationAction["PENDING"] = "pending";
    NextHomeApplicationAction["REVIEW"] = "review";
    NextHomeApplicationAction["COOWNER_DISPUTE"] = "coownerDispute";
    NextHomeApplicationAction["REVIEW_UPLOADED_DOC"] = "reviewUploadedDoc";
    // Applicant will see page for accepting or dispute their denial reason
    NextHomeApplicationAction["DISPUTE_DENIAL"] = "disputeDenial";
    // Roadblock page for when a user is in a not supported state
    NextHomeApplicationAction["UNSUPPORTED_STATE"] = "unsupportedState";
    // Applicant has closed their application (withdrawn/declined)
    NextHomeApplicationAction["CLOSED"] = "closed";
    // In Churn Retention flow, applicant will see the page for Balance Sweep
    NextHomeApplicationAction["BALANCE_SWEEP"] = "balance_sweep";
    // In Churn retention flow, if there is no CLI, user would skip notary and go to docusign page
    NextHomeApplicationAction["CHURN_RETENTION_DOCUSIGN"] = "churn_retention_docusign";
    // In Churn retention flow, it could be that user has no CLI offer and has been in approved status,
    // we would move user to a new welcome page in this scenario
    NextHomeApplicationAction["CHURN_RETENTION_SKIP_NOTARY_WELCOME"] = "churn_retention_skip_notary_welcome";
    // Applicant is waiting for pre-approval credit card to be sent, blocking and does not allow applicant to move forward
    // until SendCardPriorToApproval microflow status is marked as "received"
    NextHomeApplicationAction["EARLY_CREDIT_CARD_CONFIRMATION_PAGE"] = "early_credit_card_confirmation_page";
    // Applicant has received the pre-approval credit card, allows applicant to move forward in flow to secondary signers + notary schedule
    NextHomeApplicationAction["EARLY_CREDIT_CARD_RECEIVED_PAGE"] = "early_credit_card_received_page";
    // lien attestation page for first lien position
    NextHomeApplicationAction["FIRST_LIEN_ATTESTATION_PAGE"] = "first_lien_attestation_page";
    NextHomeApplicationAction["INCOME_VERIFICATION_IRS_FORM"] = "incomeVerificationIrsForm";
})(NextHomeApplicationAction = exports.NextHomeApplicationAction || (exports.NextHomeApplicationAction = {}));
