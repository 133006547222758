import { tryParseJson } from './stringUtils'

// Keep in sync with the backend: aven_backend/src/entity/experimentSpec.ts
export enum ExperimentType {
    // UI specific experiments
    landingPage = 'landingPage',
    preQualificationPage = 'preQualificationPage',
    multiPage = 'multiPage',
    statedIncome = 'statedIncome',
    notarySchedulingPage = 'notarySchedulingPage',
    schedulingConfirmationPage = 'schedulingConfirmationPage',
    notarySchedulingOptions = 'notarySchedulingOptions',
    beforeSessionStarts = 'beforeSessionStarts',
    personalInfoPage = 'personalInfoPage',
    employmentIncomeVerificationPage = 'employmentIncomeVerificationPage',
    coowner = 'coowner',
    // Underwriting policy specific experiments
    // One LA can only participate in one experiment of the underWritingPolicyExperiment type.
    underWritingPolicyExperiment = 'underWritingPolicyExperiment',
    // Orthogonal test of underwriting policy parameters.
    // One LA can participate in multiple experiments of the underwritingPolicyParameters type,
    // therefore secondary_hash should be used to make these participated multiple experiments 'orthogonal'.
    underwritingPolicyParameters = 'underwritingPolicyParameters',
    // Rule categories used in underwriting policies. Values come from the UnderwritingRuleCategory enum.
    underwritingRuleCategories = 'underwritingRuleCategories',
    // Cardholder engagement experiments,
    promotionalPurchase = 'promotionalPurchase',
    ascendaRewardsPortalRollout = 'ascendaRewardsPortalRollout',
    // Instant balance transfer experiments
    instantBalanceTransfer = 'instantBalanceTransfer',
    // Advisor experiments
    advisorApp = 'advisorApp',
    // PrimeV3 Engagement Comms experiments
    primeV3EngagementComms = 'primeV3EngagementComms',
    // Rollout for SI Loan Interstitial
    simpleInterestLoanInterstitial = 'simpleInterestLoanInterstitial',
    // SI conversion type
    simpleInterestLoanConversionType = 'simpleInterestLoanConversionType',
    // Using MethodFi to get personal info and prefill on the PII page
    prefillPersonalInfoFromMethodFi = 'prefillPersonalInfoFromMethodFi',
    comms = 'comms',
    // Support
    support = 'support',
    // Income Verification
    incomeVerification = 'incomeVerification',
    manualIncomeVerification = 'manualIncomeVerification',
    // Income Verification Page
    incomeVerificationPage = 'incomeVerificationPage',
    // Governs creation of a bonus if certain applicants book notary w/ in 24 hours of offer
    notaryScheduleWithin24Hours = 'notaryScheduleWithin24Hours',
    // Controls weather or not the creator challenge card is visible in aven my
    creatorChallengeCard = 'creatorChallengeCard',
    // Determines weather or not to show the spin to win card in advisor
    avenAdvisorSpinToWin = 'avenAdvisorSpinToWin',
    // Specifies which title insurance provider to use
    titleInsuranceProvider = 'titleInsuranceProvider',
    dqComms = 'dqComms',
    notaryAvPermissionsPage = 'notaryAvPermissionsPage',
    applicantNotarySession = 'applicantNotarySession',
    // automatically respond to customer's question on tickets
    automaticTicketResponder = 'automaticTicketResponder',
    // Pay It Forward (PIF) experiments
    payItForward = 'payItForward',
    nooStatedRentalIncome = 'nooStatedRentalIncome',
    documentUploadJodl = 'documentUploadJodl',

    floodInsurance = 'floodInsurance',
    // Offer page
    offerPage = 'offerPage',

    // **NOTE** If you add a value here, you MUST add it to aven_backend/src/entity/experimentSpec.ts
    // Otherwise you won't be able to create the experiment on Retool.
}

export enum ExperimentSpecGroupName {
    cypressTest = 'cypressTest', // used for cypress tests; Will add a test id to the landing page card image
    irs4506IncomeVerify = 'irs4506IncomeVerify', // Test adding irs verification option instead of photo upload
    googleAsPlacesProvider = 'googleAsPlacesProvider', // Test using google places as the address provider
    googleWidgetAutocomplete = 'googleWidgetAutocomplete', // Test using google autocomplete widget as address provider
    googleStricterResults = 'googleStricterResults', // Test using google autocomplete with stricter results
    irsForAllStatedIncome = 'irsForAllStatedIncome', // Test IRS for all as a new item on the stated income page
    irsForAllStartOfPQ = 'irsForAllStartOfPQ', // Test IRS for all in the beginning of PQ page
    irsForAllStartOfIV = 'irsForAllStartOfIV', // Test IRS for all in the beginning of Income Verification Page
    timerVariantAbove = 'timerVariantAbove', // Test timer above pq and offer page
    timerVariantBelow = 'timerVariantBelow', // Test timer below pq and offer page
    showUserInputJobTitle = 'showUserInputJobTitle', // Test if we should ask customer for job title
    seonFraudShadow = 'seonFraudShadow', // Enable seon fraud api call in shadow
    selfEmploymentBusinessPercentageRequired = 'selfEmploymentBusinessPercentageRequired', // Test show self employment business percentage
    disableManualIncomeVerificationForSelfEmployedLowFicoGroup = 'disableManualIncomeVerificationForSelfEmployedLowFicoGroup', // Disable manual income verification option for self employed low FICO group
    submitIncomeForSpouseIndividualMarried = 'submitIncomeForSpouseIndividualMarried', // Test add submit spouse income box for individual married
    methodFiPhoneNumberSsnToPii = 'methodFiPhoneNumberSsnToPii', // Test method fi phone number ssn to pii
    coownerUpdate = 'coownerUpdate', // Allow users to optimistically edit coowner list
    post2023DecFixedTerm = 'post2023DecFixedTerm', // test fixed monthly payments feature
    garamondLandingPage = 'garamondLandingPage', // test garamond font on landing page
    reservesPageTest = 'reservesPageTest', // get stated total savings on new page
    trustPilotBanner = 'trustPilotBanner', // test trustpilot banner above the fold
    manualReturnToPlaid = 'manualReturnToPlaid', // allow user into plaid flow from manual verification pages
    waitroom = 'waitroom', // test waitroom feature
    sevenHundredPlusFicoLP = 'sevenHundredPlusFicoLP', // test 700+ FICO LP
    simpleSiteLp = 'simpleSiteLp', // test simple site for default landing page
    nooCollectStatedRentalIncome = 'nooCollectStatedRentalIncome',
    multiProductLandingPage = 'multiProductLandingPage', // test multi product landing page
    experianLandingPage = 'experianLandingPage', // test experian landing page
    post2024JulyDMPrimeV3WithFlight5pct = 'post2024JulyDMPrimeV3WithFlight5pct',

    /* START primeV3OfferPresentation experiment */
    primeV3OfferPresentationControl = 'primeV3OfferPresentationControl', // Current (i.e. control) for how we present Prime V3 offers
    primeV3OfferPresentationVariantCarrot = 'primeV3OfferPresentationVariantCarrot', // "Carrot" variant: We present PrimeV3 APR as a reward for 80% line utilization on the PQ page
    primeV3OfferPresentationVariantExtraPage = 'primeV3OfferPresentationVariantExtraPage', // "Extra page" variant: Same PQ page presentation, but w/ an extra page describing Prime V3 APR
    /* END primeV3OfferPresentation experiment */

    // Pre qualification
    defaultSortedOffers = 'defaultSortedOffers', // test default backend sorted offers vs frontend sorted offers
    separatedFootnotes = 'separatedFootnotes',
    showMoreFixedTermOfferButton = 'showMoreFixedTermOfferButton', // test a "load more" button for Fixed Term offers
    compareWithOtherCreditCards = 'compareWithOtherCreditCards',

    paymentTableSidebar = 'paymentTableSidebar', // test payment table sidebar

    // NOTARY EXPERIMENTS
    preSchedulePersonaFlow = 'preSchedulePersonaFlow', // force the applicant to complete persona BEFORE scheduling their appointment
    preSelectedSlot = 'preSelectedSlot',
    notarySupportCallScheduling = 'notarySupportCallScheduling', // Will show a button to allow the applicant to schedule a support call on scheduling confirmation page
    signNowCountDown = 'signNowCountDown',
    promptBeforeAvPermissions = 'promptBeforeAvPermissions', // Warn user before requesting system permissions
    faqInNotaryWaitingRoom = 'faqInNotaryWaitingRoom',
    signNowWithANotary = 'signNowWithANotary',
    signNowOnly = 'signNowOnly', // will only show sign now button if available
    avenCarePage = 'avenCarePage', // will show aven care page
    showVideoOnConfirmation = 'showVideoOnConfirmation', // show video on scheduling confirmation page
    detailedViewAfterScheduling = 'detailedViewAfterScheduling', // prefers detailed instructions over persona text
    showNotaryExpectationsComponent = 'showNotaryExpectationsComponent',
    confirmationCoownerExplanation = 'confirmationCoownerExplanation',

    // START Stated Income Experiments
    showOptionOneStatedIncome = 'showOptionOneStatedIncome', // Show option one for stated income
    showOptionTwoStatedIncome = 'showOptionTwoStatedIncome', // Show option two for stated income
    showOptionThreeStatedIncome = 'showOptionThreeStatedIncome', // Show option three for stated income
    showOptionFourStatedIncome = 'showOptionFourStatedIncome', // Show option four for stated income
    // END Stated Income Experiments

    // START a/b/c OfferInfoCard Experiments
    showOfferInfoCashUpfront = 'showOfferInfoCashUpfront', // Show cash upfront offer info card
    hideOfferInfoCard = 'hideOfferInfoCard', // Hide offer info card
    // END a/b/c OfferInfoCard Experiments

    useSalariedInsteadOfEmployed = 'useSalariedInsteadOfEmployed',
    showIncentiveOnLandingPage = 'showIncentiveOnLandingPage',
    useIncomeDocumentUploadPortal = 'useIncomeDocumentUploadPortal', // show income document upload portal instead of regular jodl
    showStatedReferrer = 'showStatedReferrer', // asks user where they came from

    floodLossPayeeToContingency = 'floodLossPayeeToContingency', // change flood loss payee to contingency
    // Fixed term offer page
    fixedTermOfferPageVariantA = 'fixedTermOfferPageVariantA',
    fixedTermOfferPageVariantB = 'fixedTermOfferPageVariantB',

    // Rocket LP
    rocketLandingPageRedirection = 'rocketLandingPageRedirection',
    creditCardRocketLandingPageRedirection = 'creditCardRocketLandingPageRedirection',

    // Launchpad for DM
    showLaunchpadForDM = 'showLaunchpadForDM',
}

/**
 * Provides the default value for the experiment group
 */
const ExperimentEnabledDefaultValue: { [T in ExperimentSpecGroupName]: boolean } = {
    [ExperimentSpecGroupName.cypressTest]: false,
    [ExperimentSpecGroupName.irs4506IncomeVerify]: false,
    [ExperimentSpecGroupName.googleAsPlacesProvider]: false,
    [ExperimentSpecGroupName.irsForAllStatedIncome]: false,
    [ExperimentSpecGroupName.irsForAllStartOfPQ]: false,
    [ExperimentSpecGroupName.irsForAllStartOfIV]: false,
    [ExperimentSpecGroupName.timerVariantAbove]: false,
    [ExperimentSpecGroupName.timerVariantBelow]: false,
    [ExperimentSpecGroupName.showUserInputJobTitle]: false,
    [ExperimentSpecGroupName.seonFraudShadow]: false,
    [ExperimentSpecGroupName.selfEmploymentBusinessPercentageRequired]: false,
    [ExperimentSpecGroupName.disableManualIncomeVerificationForSelfEmployedLowFicoGroup]: false,
    [ExperimentSpecGroupName.submitIncomeForSpouseIndividualMarried]: false,
    [ExperimentSpecGroupName.googleWidgetAutocomplete]: false,
    [ExperimentSpecGroupName.methodFiPhoneNumberSsnToPii]: false,
    [ExperimentSpecGroupName.coownerUpdate]: false,
    [ExperimentSpecGroupName.post2023DecFixedTerm]: true,
    [ExperimentSpecGroupName.garamondLandingPage]: false,
    [ExperimentSpecGroupName.reservesPageTest]: false,
    [ExperimentSpecGroupName.trustPilotBanner]: false,
    [ExperimentSpecGroupName.manualReturnToPlaid]: false,
    [ExperimentSpecGroupName.primeV3OfferPresentationControl]: false,
    [ExperimentSpecGroupName.primeV3OfferPresentationVariantCarrot]: false,
    [ExperimentSpecGroupName.primeV3OfferPresentationVariantExtraPage]: false,
    [ExperimentSpecGroupName.waitroom]: false,
    [ExperimentSpecGroupName.defaultSortedOffers]: false,
    [ExperimentSpecGroupName.separatedFootnotes]: false,
    [ExperimentSpecGroupName.showMoreFixedTermOfferButton]: false,
    [ExperimentSpecGroupName.compareWithOtherCreditCards]: false,
    [ExperimentSpecGroupName.sevenHundredPlusFicoLP]: false,
    [ExperimentSpecGroupName.paymentTableSidebar]: false,
    [ExperimentSpecGroupName.simpleSiteLp]: false,
    [ExperimentSpecGroupName.nooCollectStatedRentalIncome]: false,
    [ExperimentSpecGroupName.googleStricterResults]: false,
    [ExperimentSpecGroupName.multiProductLandingPage]: false,
    [ExperimentSpecGroupName.experianLandingPage]: false,

    [ExperimentSpecGroupName.preSchedulePersonaFlow]: false,
    [ExperimentSpecGroupName.preSelectedSlot]: false,
    [ExperimentSpecGroupName.notarySupportCallScheduling]: false,
    [ExperimentSpecGroupName.signNowCountDown]: false,
    [ExperimentSpecGroupName.promptBeforeAvPermissions]: false,
    [ExperimentSpecGroupName.faqInNotaryWaitingRoom]: false,
    [ExperimentSpecGroupName.signNowWithANotary]: false,
    [ExperimentSpecGroupName.signNowOnly]: false,
    [ExperimentSpecGroupName.avenCarePage]: false,
    [ExperimentSpecGroupName.showVideoOnConfirmation]: false,
    [ExperimentSpecGroupName.detailedViewAfterScheduling]: false,
    [ExperimentSpecGroupName.showNotaryExpectationsComponent]: false,
    [ExperimentSpecGroupName.showOptionOneStatedIncome]: false,
    [ExperimentSpecGroupName.showOptionTwoStatedIncome]: false,
    [ExperimentSpecGroupName.showOptionThreeStatedIncome]: false,
    [ExperimentSpecGroupName.showOptionFourStatedIncome]: false,
    [ExperimentSpecGroupName.showOfferInfoCashUpfront]: false,
    [ExperimentSpecGroupName.hideOfferInfoCard]: false,
    [ExperimentSpecGroupName.useSalariedInsteadOfEmployed]: false,
    [ExperimentSpecGroupName.showIncentiveOnLandingPage]: false,
    [ExperimentSpecGroupName.useIncomeDocumentUploadPortal]: false,
    [ExperimentSpecGroupName.showStatedReferrer]: false,
    [ExperimentSpecGroupName.post2024JulyDMPrimeV3WithFlight5pct]: false,
    [ExperimentSpecGroupName.floodLossPayeeToContingency]: false,
    [ExperimentSpecGroupName.fixedTermOfferPageVariantA]: false,
    [ExperimentSpecGroupName.fixedTermOfferPageVariantB]: false,
    [ExperimentSpecGroupName.confirmationCoownerExplanation]: false,
    [ExperimentSpecGroupName.rocketLandingPageRedirection]: false,
    [ExperimentSpecGroupName.creditCardRocketLandingPageRedirection]: false,
    [ExperimentSpecGroupName.showLaunchpadForDM]: false,
} as const

type EnabledOverrides = { [key in ExperimentType]?: { [key in ExperimentSpecGroupName]?: boolean } } // can be extended to enable other types if necessary

/**
 * Returns a map of overrides with enabled values or default values for pages
 */
const EXPERIMENTS_PER_TYPE_DEFAULTS: EnabledOverrides = {
    [ExperimentType.landingPage]: {
        [ExperimentSpecGroupName.cypressTest]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.cypressTest],
        [ExperimentSpecGroupName.garamondLandingPage]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.garamondLandingPage],
        [ExperimentSpecGroupName.trustPilotBanner]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.trustPilotBanner],
        [ExperimentSpecGroupName.sevenHundredPlusFicoLP]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.sevenHundredPlusFicoLP],
        [ExperimentSpecGroupName.simpleSiteLp]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.simpleSiteLp],
        [ExperimentSpecGroupName.multiProductLandingPage]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.multiProductLandingPage],
        [ExperimentSpecGroupName.showIncentiveOnLandingPage]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.showIncentiveOnLandingPage],
        [ExperimentSpecGroupName.rocketLandingPageRedirection]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.rocketLandingPageRedirection],
        [ExperimentSpecGroupName.creditCardRocketLandingPageRedirection]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.creditCardRocketLandingPageRedirection],
        [ExperimentSpecGroupName.showLaunchpadForDM]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.showLaunchpadForDM],
    },
    [ExperimentType.personalInfoPage]: {
        [ExperimentSpecGroupName.googleAsPlacesProvider]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.googleAsPlacesProvider],
        [ExperimentSpecGroupName.seonFraudShadow]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.seonFraudShadow],
        [ExperimentSpecGroupName.googleWidgetAutocomplete]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.googleWidgetAutocomplete],
        [ExperimentSpecGroupName.googleStricterResults]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.googleStricterResults],
    },
    [ExperimentType.statedIncome]: {
        [ExperimentSpecGroupName.showOptionOneStatedIncome]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.showOptionOneStatedIncome],
        [ExperimentSpecGroupName.showOptionTwoStatedIncome]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.showOptionTwoStatedIncome],
        [ExperimentSpecGroupName.showOptionThreeStatedIncome]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.showOptionThreeStatedIncome],
        [ExperimentSpecGroupName.showOptionFourStatedIncome]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.showOptionFourStatedIncome],
        [ExperimentSpecGroupName.submitIncomeForSpouseIndividualMarried]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.submitIncomeForSpouseIndividualMarried],
        [ExperimentSpecGroupName.reservesPageTest]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.reservesPageTest],
    },
    [ExperimentType.incomeVerification]: {
        [ExperimentSpecGroupName.irs4506IncomeVerify]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.irs4506IncomeVerify],
        [ExperimentSpecGroupName.selfEmploymentBusinessPercentageRequired]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.selfEmploymentBusinessPercentageRequired],
        [ExperimentSpecGroupName.disableManualIncomeVerificationForSelfEmployedLowFicoGroup]:
            ExperimentEnabledDefaultValue[ExperimentSpecGroupName.disableManualIncomeVerificationForSelfEmployedLowFicoGroup],
    },
    [ExperimentType.manualIncomeVerification]: {
        [ExperimentSpecGroupName.manualReturnToPlaid]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.manualReturnToPlaid],
    },
    [ExperimentType.preQualificationPage]: {
        [ExperimentSpecGroupName.defaultSortedOffers]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.defaultSortedOffers],
        [ExperimentSpecGroupName.separatedFootnotes]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.separatedFootnotes],
        [ExperimentSpecGroupName.showMoreFixedTermOfferButton]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.showMoreFixedTermOfferButton],
        [ExperimentSpecGroupName.compareWithOtherCreditCards]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.compareWithOtherCreditCards],
    },
    [ExperimentType.multiPage]: {
        [ExperimentSpecGroupName.showStatedReferrer]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.showStatedReferrer],
        [ExperimentSpecGroupName.irsForAllStatedIncome]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.irsForAllStatedIncome],
        [ExperimentSpecGroupName.irsForAllStartOfPQ]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.irsForAllStartOfPQ],
        [ExperimentSpecGroupName.irsForAllStartOfIV]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.irsForAllStartOfIV],
        [ExperimentSpecGroupName.timerVariantAbove]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.timerVariantAbove],
        [ExperimentSpecGroupName.timerVariantBelow]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.timerVariantBelow],
        [ExperimentSpecGroupName.post2023DecFixedTerm]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.post2023DecFixedTerm],
        [ExperimentSpecGroupName.primeV3OfferPresentationControl]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.primeV3OfferPresentationControl],
        [ExperimentSpecGroupName.primeV3OfferPresentationVariantCarrot]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.primeV3OfferPresentationVariantCarrot],
        [ExperimentSpecGroupName.primeV3OfferPresentationVariantExtraPage]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.primeV3OfferPresentationVariantExtraPage],
        [ExperimentSpecGroupName.paymentTableSidebar]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.paymentTableSidebar],
        [ExperimentSpecGroupName.waitroom]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.waitroom],
        [ExperimentSpecGroupName.avenCarePage]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.avenCarePage],
        [ExperimentSpecGroupName.showOfferInfoCashUpfront]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.showOfferInfoCashUpfront],
        [ExperimentSpecGroupName.hideOfferInfoCard]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.hideOfferInfoCard],
    },
    [ExperimentType.employmentIncomeVerificationPage]: {
        [ExperimentSpecGroupName.showUserInputJobTitle]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.showUserInputJobTitle],
        [ExperimentSpecGroupName.useSalariedInsteadOfEmployed]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.useSalariedInsteadOfEmployed],
    },
    [ExperimentType.prefillPersonalInfoFromMethodFi]: {
        [ExperimentSpecGroupName.methodFiPhoneNumberSsnToPii]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.methodFiPhoneNumberSsnToPii],
    },
    [ExperimentType.coowner]: {
        [ExperimentSpecGroupName.coownerUpdate]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.coownerUpdate],
    },
    [ExperimentType.notarySchedulingPage]: {
        [ExperimentSpecGroupName.preSelectedSlot]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.preSelectedSlot],
        [ExperimentSpecGroupName.signNowWithANotary]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.signNowWithANotary],
        [ExperimentSpecGroupName.signNowOnly]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.signNowOnly],
        [ExperimentSpecGroupName.preSchedulePersonaFlow]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.preSchedulePersonaFlow],
    },
    [ExperimentType.schedulingConfirmationPage]: {
        [ExperimentSpecGroupName.notarySupportCallScheduling]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.notarySupportCallScheduling],
        [ExperimentSpecGroupName.showVideoOnConfirmation]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.showVideoOnConfirmation],
        [ExperimentSpecGroupName.detailedViewAfterScheduling]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.detailedViewAfterScheduling],
        [ExperimentSpecGroupName.showNotaryExpectationsComponent]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.showNotaryExpectationsComponent],
        [ExperimentSpecGroupName.confirmationCoownerExplanation]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.confirmationCoownerExplanation],
    },
    [ExperimentType.notaryAvPermissionsPage]: {
        [ExperimentSpecGroupName.promptBeforeAvPermissions]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.promptBeforeAvPermissions],
    },
    [ExperimentType.beforeSessionStarts]: {
        [ExperimentSpecGroupName.signNowCountDown]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.signNowCountDown],
    },
    [ExperimentType.applicantNotarySession]: {
        [ExperimentSpecGroupName.faqInNotaryWaitingRoom]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.faqInNotaryWaitingRoom],
    },
    [ExperimentType.documentUploadJodl]: {
        [ExperimentSpecGroupName.useIncomeDocumentUploadPortal]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.useIncomeDocumentUploadPortal],
    },
    [ExperimentType.floodInsurance]: {
        [ExperimentSpecGroupName.floodLossPayeeToContingency]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.floodLossPayeeToContingency],
    },
    [ExperimentType.offerPage]: {
        [ExperimentSpecGroupName.fixedTermOfferPageVariantA]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.fixedTermOfferPageVariantA],
        [ExperimentSpecGroupName.fixedTermOfferPageVariantB]: ExperimentEnabledDefaultValue[ExperimentSpecGroupName.fixedTermOfferPageVariantB],
    },
}

export type ExperimentsOverrides = { [key in ExperimentType]?: Array<ExperimentSpecGroupName> }

export type AbTestExperimentsFactoryParams = {
    appSessionStorage: { getItem: (key: string) => string | undefined }
    sessionStorageOverridesKey: string
    logEventFn: (eventName: string, metadata: any) => void
}

/**
 * Factory function to create an object that can be used to query if an experiment is enabled
 *
 * THIS FUNCTION ASSUMES OUR COMMON PATTERN OF RETRIEVING THE OVERRIDES FROM SESSION STORAGE
 * LOOK AT aven_frontend/aven/src/services/auth.ts::saveAuthResponse FOR AN EXAMPLE OF HOW TO SAVE THE OVERRIDES
 * THIS PATTERN IS FAR FROM IDEAL, BUT THE REFACTOR TO MAKE IT EXPLICITLY PASSED IN WOULD BE TOO LARGE WITH THE CURRENT CODEBASE
 *
 * @param appSessionStorage
 * @param sessionStorageOverridesKey
 * @param logEventFn
 */
export const abTestExperimentsFactory = ({
    appSessionStorage,
    sessionStorageOverridesKey,
    logEventFn,
}: AbTestExperimentsFactoryParams): {
    isExperimentEnabled: (experimentType: ExperimentType, experimentValue: ExperimentSpecGroupName) => boolean
} => {
    const getEnabledOverrides = (): EnabledOverrides => {
        // There are some cases in which experimentsOverrides is undefined
        // Setting '{}' as default value to avoid errors
        const enabledOverrides = tryParseJson<ExperimentsOverrides>(appSessionStorage.getItem(sessionStorageOverridesKey) || '{}') || {}

        // Clone the default values
        const result = JSON.parse(JSON.stringify(EXPERIMENTS_PER_TYPE_DEFAULTS)) as EnabledOverrides

        // Apply the overrides
        const enabledOverridesEntries = Object.entries(enabledOverrides) as [ExperimentType, ExperimentSpecGroupName[]][]
        for (const [experimentType, enabledGroups] of enabledOverridesEntries) {
            // Ensure the experiment type is not undefined
            const experimentTypeObject: { [K in ExperimentSpecGroupName]?: boolean } = result[experimentType] || {}
            for (const group of enabledGroups) {
                experimentTypeObject[group] = true
            }
            result[experimentType] = experimentTypeObject
        }

        return result
    }

    return {
        isExperimentEnabled: (experimentType: ExperimentType, experimentValue: ExperimentSpecGroupName): boolean => {
            const enabledOverrides = getEnabledOverrides()

            const result = enabledOverrides[experimentType]?.[experimentValue] || false

            logEventFn('event_ab_experiment_queried', { type: experimentType, value: experimentValue, result })
            return result
        },
    }
}
