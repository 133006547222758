/**
 * ------------------------------------------- Configuration of Flows -------------------------------------------
 *
 *       q - r - s  <- optional flow
 *       |
 *   a - b - c - d - e - f  <- main flow
 *       | /         |
 *       g - h       i - j  <- optional flow
 *                       |
 *                       k - l - m - n - o  <- optional flow
 *
 * mainFlow = [a, b, c, d, e, f]
 * Optional flows: qFlow = [q, r, s]; gFlow = [g, h]; iFlow = [i, j]; kFlow = [k, l, m, n, o]
 * Each flow is described by an array of page paths (strings) starting at index 0 and ending at the last index.
 * !!!Note that all page paths must be unique!!!!, otherwise a secondary identifier must be used for discrimination.
 *
 * optionalFlowMap = {b: {qOption: qFlow, gOption: gFlow}, c: {gOption: gFlow}, e: {iOption: iFlow}, j: {kOption: kFlow}}
 * The optional flow map describes the children of each page in terms of secondary (optional) flows.
 *
 * qOption = RouteOption.qFlowIdentifier, gOption = RouteOption.gFlowIdentifier, etc...
 * Each page (parent) may have several optional routes (children). A particular child of a page is specified
 * by referencing a RouteOption parameter (enum). See f.e. the optional routes accessible from page b.
 *
 * Once the end of an optional flow is reached, !!!the next page will be the parent of that optional flow!!!
 */

import { marketingPagePaths } from '@/routes/marketingRoutes'
import { checkAreAllPathsUnique, checkPathsMatch, getNormalizedPath, RouteOption, tryGetNextFlowPath, tryGetOptionalPath } from '@/flow/flowUtility'
import { authPagePaths } from '@/routes/authRoutes'
import { docRoutePaths } from '@/routes/docRoutes'
import { uccPagePaths } from '@/routes/uccRoutes'

export const uccEntryPagePaths: string[] = [marketingPagePaths.LANDING_UCC]

export const uccReturnPagePathPairs: Record<string, string> = {
    [getNormalizedPath(authPagePaths.OFFER_RETURN)]: uccPagePaths.OFFER_PREVIEW,
    [getNormalizedPath(docRoutePaths.DOCS_UPLOAD_INCOME_VERIFICATION)]: uccPagePaths.OFFER_PREVIEW,
    [getNormalizedPath(uccPagePaths.INCOME_VERIFICATION_IN_PROGRESS)]: uccPagePaths.OFFER_PREVIEW,
    [getNormalizedPath(uccPagePaths.INCOME_DOCUMENT_UPLOAD_PORTAL)]: uccPagePaths.OFFER_PREVIEW,
    [getNormalizedPath(authPagePaths.INCOME_VERIFICATION_RETURN)]: uccPagePaths.EMPLOYMENT_INCOME_VERIFICATION,
}

export const uccFlow: string[] = [
    // TODO UCC: Remove stated usage when UW supports ucc offers
    uccPagePaths.STATED_USAGE,
    uccPagePaths.PERSONAL_INFO,
    uccPagePaths.VERIFY_IDENTITY,
    uccPagePaths.CO_APPLICANT_VERIFY_IDENTITY,
    uccPagePaths.STATED_INCOME,
    uccPagePaths.ASSET_VERIFICATION,
    uccPagePaths.PRE_QUALIFICATION,
    uccPagePaths.EMPLOYMENT_INCOME_VERIFICATION,
    uccPagePaths.INCOME_VERIFICATION,
    uccPagePaths.OFFER_PREVIEW,
    uccPagePaths.START_KBA,
    uccPagePaths.DOCUSIGN,
    uccPagePaths.WELCOME_PAGE,
]

const identityQuestionsFlow: string[] = [uccPagePaths.IDENTITY_QUESTIONS]
const ssnVerificationFlow: string[] = [uccPagePaths.SSN_VERIFICATION]
const identityFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.identityQuestions, identityQuestionsFlow],
    [RouteOption.ssnVerification, ssnVerificationFlow],
])

const coApplicantSsnVerificationFlow: string[] = [uccPagePaths.CO_APPLICANT_SSN_VERIFICATION]
const coApplicantIdentityQuestionsFlow: string[] = [uccPagePaths.CO_APPLICANT_IDENTITY_QUESTIONS]
const coApplicantIdentityFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.identityQuestions, coApplicantIdentityQuestionsFlow],
    [RouteOption.coApplicantSsnVerification, coApplicantSsnVerificationFlow],
])

const kbaFlow: string[] = [uccPagePaths.KBA_QUESTIONS]
const kbaFlowGroup: Map<string, string[]> = new Map([[RouteOption.identityQuestions, kbaFlow]])

const experianFrozenFlow: string[] = [uccPagePaths.EXPERIAN_FROZEN]
const coApplicantExperianFrozenFlow: string[] = [uccPagePaths.CO_APPLICANT_EXPERIAN_FROZEN]

const irsForAllPQFlow = [uccPagePaths.IRS_FOR_ALL_PQ]
const irsForAllIVFlow = [uccPagePaths.IRS_FOR_ALL_IV]
const rentalIncomeVerificationFlow = [uccPagePaths.RENTAL_INCOME_VERIFICATION, uccPagePaths.CURRENT_RENT_VERIFICATION_SELECTION, uccPagePaths.CURRENT_RENT_VERIFICATION_DOCUMENT_UPLOAD]
const employmentIncomeVerificationGroup: Map<string, string[]> = new Map([
    [RouteOption.irsForAllIV, irsForAllIVFlow],
    [RouteOption.rentalIncomeVerification, rentalIncomeVerificationFlow],
])

const preQualificationFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.experianFrozen, experianFrozenFlow],
    [RouteOption.coApplicantExperianFrozen, coApplicantExperianFrozenFlow],
    [RouteOption.coApplicantSsnVerification, coApplicantSsnVerificationFlow],
    [RouteOption.irsForAllPQ, irsForAllPQFlow],
])

const irsFormFlow = [uccPagePaths.IRS_FORM_VERIFICATION]
const payStubsFlow = [uccPagePaths.PAY_STUB_VERIFICATION]
const taxReturnFlow = [uccPagePaths.TAX_RETURN_VERIFICATION]
const otherIncomeVerificationFlow = [uccPagePaths.OTHER_INCOME_VERIFICATION]
const fortifiedIncomeVerificationFlow = [uccPagePaths.FORTIFIED_INCOME_VERIFICATION]
const selfEmploymentBusinessPercentageFlow = [uccPagePaths.SELF_EMPLOYMENT_BUSINESS_PERCENTAGE]
const incomeVerificationFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.irsFormVerify, irsFormFlow],
    [RouteOption.taxReturnVerify, taxReturnFlow],
    [RouteOption.payStubsVerify, payStubsFlow],
    [RouteOption.otherIncomeVerify, otherIncomeVerificationFlow],
    [RouteOption.fortifiedIncomeVerify, fortifiedIncomeVerificationFlow],
    [RouteOption.selfEmploymentBusinessPercentage, selfEmploymentBusinessPercentageFlow],
    [RouteOption.rentalIncomeVerification, rentalIncomeVerificationFlow],
])

const awaitingManualDocumentVerificationFlow = [uccPagePaths.AWAITING_MANUAL_DOCUMENT_VERIFICATION]
const offerPreviewFlowGroup: Map<string, string[]> = new Map([[RouteOption.awaitingManualDocumentVerification, awaitingManualDocumentVerificationFlow]])

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map([
    [uccPagePaths.VERIFY_IDENTITY, identityFlowGroup],
    [uccPagePaths.CO_APPLICANT_VERIFY_IDENTITY, coApplicantIdentityFlowGroup],
    [uccPagePaths.PRE_QUALIFICATION, preQualificationFlowGroup],
    [uccPagePaths.START_KBA, kbaFlowGroup],
    [uccPagePaths.INCOME_VERIFICATION, incomeVerificationFlowGroup],
    [uccPagePaths.EMPLOYMENT_INCOME_VERIFICATION, employmentIncomeVerificationGroup],
    [uccPagePaths.OFFER_PREVIEW, offerPreviewFlowGroup],
])

// Could be a unit test
if (!checkAreAllPathsUnique(uccFlow, optionalFlowMap)) {
    throw new Error('origination page paths not unique!')
}

export const getUccNextRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    if (routeOption) {
        return tryGetOptionalPath(currentPath, optionalFlowMap, routeOption)
    }
    if (uccEntryPagePaths.some((path) => checkPathsMatch(path, currentPath))) {
        return uccFlow[0]
    }

    const matchingReturnPagePath: string | undefined = uccReturnPagePathPairs[getNormalizedPath(currentPath)]
    if (matchingReturnPagePath) {
        return matchingReturnPagePath
    }

    return tryGetNextFlowPath(currentPath, uccFlow, optionalFlowMap)
}
