import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { autoUnderwritingMetadata } from '@/services/autoApi'
import { ref } from 'vue'
import { Ref } from 'vue/types/v3-generated'
import { logger } from '@/utils/logger'
import { toFormattedAprStripTrailingZerosAfterDecimalPoint } from '@/mixins/format'

export const HIGH_BT_CO_FEE_VARIABLE_NAME = 'IncreasedBtCoVariant'
export const PRIME_V2_VARIABLE_NAME = 'PrimeV2Variant'

export const IGNORE_STATED_USAGE_VARIABLE_NAME = 'IgnoreStatedUsageVariant'

// Keep in sync with UnderwritingMetaData in aven_backend/src/experiments/getExperimentsWithMetadata.ts
export interface UnderwritingMetaData {
    ExperimentName: string
    MinAPR: number
    MaxAPR: number
    MaxLineSize: number
    BTFee: number
    MinBTFeePercent: number
    MaxBTFeePercent: number
    COFee: number
    MinCOFeePercent: number
    MaxCOFeePercent: number
    rateIndexShortName: string
    rateIndexFullName: string
    rateValuePercentage: string // e.g., 1.75%
    rateIndexEffectiveDate: string
    generatePrimeV2Variant: boolean
    generatePrimeV3Variant: boolean
    generateHighBtCoVariant: boolean
    teaserPromoEnabled?: boolean
    teaserPromoApr?: number
    teaserPromoDurationInMonth?: number
    cashbackRatio?: number
    boostCashBackRatio?: number | null
    boostCashBackDollarLimitPerCycle?: number
    boostCashBackWithTargetMerchants?: boolean
    shouldUpdateCashbackAfterDisableAutoPay?: boolean
    cashbackAfterDisableAutoPay?: number
    showPrimeV3InOG?: boolean
    primeV3AprReduction: number
    primeV3LineSizeDrawRatio: number
    primeV3LineSizeDrawEffectiveDays: number
    cashOutUpfrontEnabled?: boolean
    cashOutUpfrontV2Enabled?: boolean
    cashOutUpfrontLineSizeDrawRatio?: number
    helocLowestGuaranteedEnabled?: boolean
    allowLongTermInstallmentLoans?: boolean
    enableFortifiedIncomeFlow?: boolean
    originationFeePercent?: number
    firstDrawFeePercent?: number
    drawPeriodYears?: number
    isHelocRefiEnabled?: boolean
    enableCashOutUpfrontSpecificPqPage?: boolean
    rewardRateHotels?: number
    rewardRateFlights?: number
    isNooV2Enabled: boolean
    enableRevolvingShadowLimit?: boolean
    revolvingShadowLimitCap?: number
    minFicoScore: number
    minVantageScore: number
    minLineDrawDownForActivation: number
    titleContingencyThreshold?: number
}

/**
 * DEPRECATED: All in the wild uses of this fn should be replaced with useUnderwritingMetadata
 * and passed from a vue component so vue can manage the reactivity.
 * @deprecated
 */
export const getUnderwritingMetadata = (): UnderwritingMetaData => {
    const dataStr = appSessionStorage.getItem(sessionStorageKey.underwritingMetaData)
    if (!dataStr) {
        throw new Error('Underwriting metadata is not set in session storage')
    }

    const data = JSON.parse(dataStr)
    const uwMetaData: UnderwritingMetaData = {
        ExperimentName: data.ExperimentName,
        MinAPR: data.MinAPR,
        MaxAPR: data.MaxAPR,
        MaxLineSize: data.MaxLineSize,
        BTFee: data.BTFee,
        MinBTFeePercent: data.MinBTFeePercent,
        MaxBTFeePercent: data.MaxBTFeePercent,
        COFee: data.COFee,
        MinCOFeePercent: data.MinCOFeePercent,
        MaxCOFeePercent: data.MaxCOFeePercent,
        rateIndexShortName: data.rateIndexShortName,
        rateIndexFullName: data.rateIndexFullName,
        rateValuePercentage: data.rateValuePercentage,
        rateIndexEffectiveDate: data.rateIndexEffectiveDate,
        generatePrimeV2Variant: data.generatePrimeV2Variant,
        generatePrimeV3Variant: data.generatePrimeV3Variant,
        generateHighBtCoVariant: data.generateHighBtCoVariant,
        teaserPromoEnabled: data.teaserPromoEnabled,
        teaserPromoApr: data.teaserPromoApr,
        teaserPromoDurationInMonth: data.teaserPromoDurationInMonth,
        cashbackRatio: data.cashbackRatio,
        boostCashBackRatio: data.boostCashBackRatio,
        boostCashBackDollarLimitPerCycle: data.boostCashBackDollarLimitPerCycle,
        boostCashBackWithTargetMerchants: data.boostCashBackWithTargetMerchants,
        shouldUpdateCashbackAfterDisableAutoPay: data.shouldUpdateCashbackAfterDisableAutoPay,
        cashbackAfterDisableAutoPay: data.cashbackAfterDisableAutoPay,
        showPrimeV3InOG: data.showPrimeV3InOG,
        primeV3AprReduction: data.primeV3AprReduction,
        primeV3LineSizeDrawRatio: data.primeV3LineSizeDrawRatio,
        primeV3LineSizeDrawEffectiveDays: data.primeV3LineSizeDrawEffectiveDays,
        // TODO: Refactor into a single field?
        cashOutUpfrontEnabled: data.cashOutUpfrontEnabled,
        cashOutUpfrontV2Enabled: data.cashOutUpfrontV2Enabled,
        cashOutUpfrontLineSizeDrawRatio: data.cashOutUpfrontLineSizeDrawRatio,
        helocLowestGuaranteedEnabled: data.helocLowestGuaranteedEnabled,
        allowLongTermInstallmentLoans: data.allowLongTermInstallmentLoans,
        enableFortifiedIncomeFlow: data.enableFortifiedIncomeFlow,
        originationFeePercent: data.originationFeePercent,
        firstDrawFeePercent: data.firstDrawFeePercent,
        drawPeriodYears: data.drawPeriodYears,
        enableCashOutUpfrontSpecificPqPage: data.enableCashOutUpfrontSpecificPqPage,
        isHelocRefiEnabled: data.isHelocRefiEnabled,
        rewardRateHotels: data.rewardRateHotels,
        rewardRateFlights: data.rewardRateFlights,
        isNooV2Enabled: data.isNooV2Enabled,
        enableRevolvingShadowLimit: data.enableRevolvingShadowLimit,
        revolvingShadowLimitCap: data.revolvingShadowLimitCap,
        minFicoScore: data.minFicoScore,
        minVantageScore: data.minVantageScore,
        minLineDrawDownForActivation: data.minLineDrawDownForActivation,
        titleContingencyThreshold: data.titleContingencyThreshold,
    }
    return uwMetaData
}

export const isUnderwritingMetadataReady = (): boolean => {
    const data = appSessionStorage.getItem(sessionStorageKey.underwritingMetaData)
    return !!data
}

export const getAutoUnderwritingMetadata = async (): Promise<UnderwritingMetaData> => {
    const autoUnderwritingMetadataResponse = await autoUnderwritingMetadata()
    return autoUnderwritingMetadataResponse.data.payload.underwritingMetadata
}

export const maybeSetUnderwritingMetadata = (uwMetadata?: UnderwritingMetaData): void => {
    if (!uwMetadata) {
        return
    }

    appSessionStorage.setItem(sessionStorageKey.underwritingMetaData, JSON.stringify(uwMetadata))
    // Let the app know that the uw metadata is ready
    const uwMetadataObtainedEvent = new CustomEvent('uw-metadata-obtained', { detail: uwMetadata })
    window.dispatchEvent(uwMetadataObtainedEvent)
}

export const useUnderwritingMetadata = (): Ref<UnderwritingMetaData> => {
    const uwMetaData = ref({} as UnderwritingMetaData)

    try {
        uwMetaData.value = getUnderwritingMetadata()
    } catch (error) {
        logger.error('Failed to get underwriting metadata', error)
    }

    window.addEventListener('uw-metadata-obtained', () => {
        try {
            uwMetaData.value = getUnderwritingMetadata()
        } catch (error) {
            logger.error('Failed to get underwriting metadata', error)
        }
    })

    return uwMetaData
}

export const getCashbackRatio = (uwMetadata: UnderwritingMetaData): string => {
    const hasBoostCashback = !!uwMetadata.boostCashBackRatio && uwMetadata.boostCashBackRatio > 0 && !uwMetadata.boostCashBackWithTargetMerchants

    if (hasBoostCashback) {
        return toFormattedAprStripTrailingZerosAfterDecimalPoint(uwMetadata.boostCashBackRatio ?? 0)
    }
    return toFormattedAprStripTrailingZerosAfterDecimalPoint(uwMetadata.cashbackRatio ?? 0)
}
