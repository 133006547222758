import { checkAreAllPathsUnique, getNormalizedPath, RouteOption, tryGetNextFlowPath, tryGetOptionalPath } from '@/flow/flowUtility'
import { authPagePaths } from '@/routes/authRoutes'
import { originationPagePaths } from '@/routes/originationRoutes'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { cliAndAprReductionAndBalanceSweepPagePaths } from '@/routes/cliAndAprReductionAndBalanceSweepRoutes'
import { creditLimitIncreasePagePaths } from '@/routes/creditLimitIncreaseRoutes'
import { logger } from '@/utils/logger'

export const cliAndAprReductionAndBalanceSweepReturnPagePathPairs: Record<string, string> = {
    [getNormalizedPath(authPagePaths.OFFER_RETURN)]: cliAndAprReductionAndBalanceSweepPagePaths.OFFER_PREVIEW,
    [getNormalizedPath(authPagePaths.INCOME_VERIFICATION_RETURN)]: originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION,
}

export const cliAndAprReductionAndBalanceSweepBackGuardPagePaths: string[] = [
    cliAndAprReductionAndBalanceSweepPagePaths.OFFER_PREVIEW,
    originationPagePaths.AWAITING_MANUAL_DOCUMENT_VERIFICATION,
    sharedPagePaths.THANKS,
    sharedPagePaths.LOAN_NOT_APPROVED,
].map(getNormalizedPath)

export const cliAndAprReductionAndBalanceSweepFlow: string[] = [
    originationPagePaths.STATED_INCOME,
    cliAndAprReductionAndBalanceSweepPagePaths.PRE_QUALIFICATION,
    originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION,
    originationPagePaths.INCOME_VERIFICATION,
    cliAndAprReductionAndBalanceSweepPagePaths.OFFER_PREVIEW,
    creditLimitIncreasePagePaths.UPDATE_MARITAL_STATUS,
    originationPagePaths.FLOOD_INSURANCE_VERIFICATION,
    originationPagePaths.SECONDARY_SIGNERS,
    originationPagePaths.SECONDARY_SIGNERS_UPDATE,
    originationPagePaths.TRUST_INFO_SUBMISSION,
    originationPagePaths.FORWARD_TO_NOTARIZATION,
]

const experianFrozenFlow: string[] = [originationPagePaths.EXPERIAN_FROZEN]
const coApplicantExperianFrozenFlow: string[] = [originationPagePaths.CO_APPLICANT_EXPERIAN_FROZEN]

const irsForAllPQFlow = [originationPagePaths.IRS_FOR_ALL_PQ]
const irsForAllIVFlow = [originationPagePaths.IRS_FOR_ALL_IV]
const employmentIncomeVerificationGroup: Map<string, string[]> = new Map([[RouteOption.irsForAllIV, irsForAllIVFlow]])

const disputableProviderDataFlow: string[] = [originationPagePaths.DISPUTE_PROVIDER_DATA]
const balanceSweepFlow = [cliAndAprReductionAndBalanceSweepPagePaths.BALANCE_SWEEP]
const preQualificationFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.experianFrozen, experianFrozenFlow],
    [RouteOption.coApplicantExperianFrozen, coApplicantExperianFrozenFlow],
    [RouteOption.disputableProviderData, disputableProviderDataFlow],
    [RouteOption.irsForAllPQ, irsForAllPQFlow],
    [RouteOption.balanceSweepPQ, balanceSweepFlow],
])

const irsFormFlow = [originationPagePaths.IRS_FORM_VERIFICATION]
const payStubsFlow = [originationPagePaths.PAY_STUB_VERIFICATION]
const taxReturnFlow = [originationPagePaths.TAX_RETURN_VERIFICATION]
const otherIncomeVerificationFlow = [originationPagePaths.OTHER_INCOME_VERIFICATION]
const incomeVerificationFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.irsFormVerify, irsFormFlow],
    [RouteOption.taxReturnVerify, taxReturnFlow],
    [RouteOption.payStubsVerify, payStubsFlow],
    [RouteOption.otherIncomeVerify, otherIncomeVerificationFlow],
])

const supportingInfoFlow = [originationPagePaths.PROVIDE_SUPPORTING_INFORMATION]
const awaitingManualDocumentVerificationFlow = [originationPagePaths.AWAITING_MANUAL_DOCUMENT_VERIFICATION]
const retentionDocuSignFlow = [cliAndAprReductionAndBalanceSweepPagePaths.DOCUSIGN]
const offerPreviewFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.supportingInfo, supportingInfoFlow],
    [RouteOption.awaitingManualDocumentVerification, awaitingManualDocumentVerificationFlow],
    [RouteOption.retentionOfferAcceptedNoCliBenefit, retentionDocuSignFlow],
])

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map([
    [cliAndAprReductionAndBalanceSweepPagePaths.PRE_QUALIFICATION, preQualificationFlowGroup],
    [originationPagePaths.INCOME_VERIFICATION, incomeVerificationFlowGroup],
    [originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION, employmentIncomeVerificationGroup],
    [cliAndAprReductionAndBalanceSweepPagePaths.OFFER_PREVIEW, offerPreviewFlowGroup],
])

// Could be a unit test
if (!checkAreAllPathsUnique(cliAndAprReductionAndBalanceSweepFlow, optionalFlowMap)) {
    throw new Error('cliAndAprReductionAndBalanceSweepFlow page paths not unique!')
}

export const getCliAndAprReductionAndBalanceSweepNextRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    logger.info(`churn retention flow routing with currentPath ${currentPath} and routeOption ${routeOption}`)
    if (routeOption) {
        return tryGetOptionalPath(currentPath, optionalFlowMap, routeOption)
    }

    const matchingReturnPagePath: string | undefined = cliAndAprReductionAndBalanceSweepReturnPagePathPairs[getNormalizedPath(currentPath)]
    if (matchingReturnPagePath) {
        return matchingReturnPagePath
    }

    return tryGetNextFlowPath(currentPath, cliAndAprReductionAndBalanceSweepFlow, optionalFlowMap)
}
